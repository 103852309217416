<template>
  <!-- Start Containerbar -->
  <div id="containerbar">
    <!-- Start Rightbar -->
    <div class="rightbar">
      <!-- Start Breadcrumbbar -->
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
            <h4 class="page-title">{{ $t("reception_history.supplies") }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard">{{ $t("reception_history.home") }}</a>
                </li>
                <li class="breadcrumb-item">
                  {{ $t("reception_history.reception") }}
                </li>
                <li class="breadcrumb-item">
                  <a href="#">{{ $t(title) }}</a>
                </li>
              </ol>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 content-button">
            <button
              class="btn btn-outline-success"
              v-if="showAction(2) || showAction(3) || showAction(4)"
              @click="changeAction(1)"
            >
              <i class="feather icon-arrow-left mr-1"></i
              >{{ $t("global.return_to") }}
            </button>
          </div>
        </div>
      </div>
      <div class="contentbar">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <AlertMessageComponent ref="alertMessageComponent" />
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col">
                    <h5 class="card-title mb-0">
                      <i class="feather icon-archive mr-2"></i
                      >{{ $t(titleAction) }}
                    </h5>
                  </div>
                  <div class="col-md-4">
                    <v-autocomplete
                      :disabled="!showAction(1)"
                      v-if="isMoreThanTwoBranchOffices"
                      @change="listAllByBranchOffice()"
                      auto-select-first
                      :items="branchOffices"
                      no-data-text="Sin resultados"
                      attach
                      v-model="branchOffice"
                      clearable
                      label="Sucursal de Origen"
                    >
                    </v-autocomplete>
                  </div>
                </div>
              </div>

              <div v-show="showAction(1)">
                <ReceptionTableComponent
                  ref="tableReceptionHistory"
                  :headers="headers"
                  :isButton="true"
                  :isDetail="withPermissionName(permissions.REC_HIS_REC_01)"
                  :isButtonPDF="
                    withPermissionName(permissions.REC_HIS_DL_PDF_02)
                  "
                  @detail="getDetail"
                  @excelTable="excelTable"
                  @downloadPDF="pdf"
                />
              </div>

              <!-- Dispensacion informacion de la receta -->
              <div v-if="showAction(2) || showAction(3) || showAction(4)">
                <div class="card">
                  <div class="card-body">
                    <div id="informacionEnvio">
                      <div class="row">
                        <div class="col-md-4">
                          <h5 class="text-fmedical-blue">
                            <i class="feather icon-truck mr-2"></i>
                            {{ $t("reception_history.shipping_information") }}
                          </h5>
                        </div>
                        <div class="col-md-4">
                          <h5
                            :class="{
                              'text-fmedical-green': leftovers == 0,
                              'text-fmedical-yellow': leftovers > 0,
                            }"
                          >
                            <i class="fa fa-plus" aria-hidden="true"></i>
                            {{ $t("reception_history.leftovers") }}
                            {{ leftovers }}
                          </h5>
                        </div>
                        <div class="col-md-4">
                          <h5
                            :class="{
                              'text-fmedical-green': missing == 0,
                              'text-fmedical-red': missing > 0,
                            }"
                          >
                            <i
                              class="fa fa-minus-circle"
                              aria-hidden="true"
                            ></i>
                            {{ $t("reception_history.missing") }}
                            {{ missing }}
                          </h5>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 col-lg-6 col-xl-4 pb-0 pt-0">
                          <v-alert
                            class="card card-detail icon"
                            text
                            color="#023047"
                          >
                            <i class="feather icon-map-pin"></i>
                            <strong>
                              <span> {{ $t("reception_history.origin") }}</span>
                            </strong>
                            <p>{{ model.branchOfficeOrigin }}</p>
                          </v-alert>
                        </div>
                        <div class="col-md-6 col-lg-6 col-xl-3 pb-0 pt-0">
                          <v-alert
                            class="card card-detail icon"
                            text
                            color="#023047"
                          >
                            <i
                              class="feather icon-user text-fmedical-blue mr-1"
                            ></i>
                            <strong>
                              <span> {{ $t("reception_pending.driver") }}</span>
                            </strong>
                            <p>{{ model.driver }}</p>
                          </v-alert>
                        </div>
                        <div class="col-md-6 col-lg-6 col-xl-3 pb-0 pt-0">
                          <v-alert
                            class="card card-detail icon"
                            text
                            color="#023047"
                          >
                            <i
                              class="feather icon-calendar text-fmedical-blue mr-1"
                            ></i>
                            <strong>
                              <span>
                                {{ $t("reception_pending.date_sent") }}</span
                              >
                            </strong>
                            <p>{{ model.shippingDateText }}</p>
                          </v-alert>
                        </div>
                        <div class="col-md-6 col-lg-6 col-xl-2 pb-0 pt-0">
                          <v-alert
                            class="card card-detail icon"
                            text
                            color="#023047"
                          >
                            <i
                              class="feather icon-truck text-fmedical-blue mr-1"
                            ></i>
                            <strong>
                              <span> {{ $t("reception_pending.plate") }}</span>
                            </strong>
                            <p>{{ model.licencePlate }}</p>
                          </v-alert>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div id="informacionRecepcion">
                      <div class="row">
                        <div class="col-md-12">
                          <h5 class="text-fmedical-blue">
                            <i class="feather icon-file-text mr-2"></i>
                            {{ $t("reception_history.reception_information") }}
                          </h5>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3 col-lg-3 col-xl-2 pb-0 pt-0">
                          <v-alert
                            class="card card-detail icon"
                            text
                            color="#023047"
                          >
                            <i
                              class="feather icon-map-pin text-fmedical-blue"
                            ></i>
                            <strong>
                              <span> {{ $t("reception_history.status") }}</span>
                            </strong>
                            <p>{{ model.statusText }}</p>
                          </v-alert>
                        </div>
                        <div class="col-md-6 col-lg-6 col-xl-3 pb-0 pt-0">
                          <v-alert
                            class="card card-detail icon"
                            text
                            color="#023047"
                          >
                            <i
                              class="feather icon-calendar text-fmedical-blue mr-1"
                            ></i>
                            <strong>
                              <span>
                                {{ $t("reception_history.date_receipt") }}</span
                              >
                            </strong>
                            <p>{{ model.createDateText }}</p>
                          </v-alert>
                        </div>
                        <div class="col-md-6 col-lg-6 col-xl-5 pb-0 pt-0">
                          <v-alert
                            class="card card-detail icon"
                            text
                            color="#023047"
                          >
                            <i
                              class="feather icon-message-square text-fmedical-blue mr-1"
                            ></i>
                            <strong>
                              <span>
                                {{ $t("reception_history.comment") }}</span
                              >
                            </strong>
                            <p>
                              {{
                                model.comment == ""
                                  ? "SIN COMENTARIOS"
                                  : model.comment
                              }}
                            </p>
                          </v-alert>
                        </div>

                        <div class="col-md-3 col-lg-2 col-xl-2 pb-0 pt-0">
                          <v-alert
                            class="hand card card-pdf pdf"
                            @click="pdf(model)"
                            text
                            color="#8E0000"
                          >
                            <i class="mdi mdi-file-pdf"></i>
                            <strong>
                              <span> PDF</span>
                            </strong>
                            <p>
                              Descargar
                            </p>
                          </v-alert>
                        </div>
                      </div>
                    </div>
                    <br />
                    <v-divider></v-divider>
                    <div class="row p-3 pt-4">
                      <div class="table-responsive br">
                        <table class="table table-hover table-striped">
                          <thead class="thead-dark">
                            <tr class="regular">
                              <th scope="col">#</th>
                              <th scope="col" class="text-center">
                                {{ $t("reception_history.financing") }}
                              </th>
                              <th scope="col">
                                {{ $t("reception_history.causes") }}
                              </th>
                              <th scope="col">
                                {{ $t("reception_history.product") }}
                              </th>
                              <th scope="col" class="text-center">
                                {{ $t("reception_history.lot") }}
                              </th>
                              <th scope="col" class="text-center">
                                {{ $t("reception_history.expiration") }}
                              </th>
                              <th scope="col" class="text-center">
                                {{ $t("reception_history.quantity_shipped") }}
                              </th>
                              <th scope="col" class="text-center">
                                {{ $t("reception_history.quantity_received") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, index) in model.orderStockList"
                              :key="item.idOrderStock"
                            >
                              <td>{{ index + 1 }}</td>
                              <td class="text-center">
                                {{ item.fiscalFoundKey }}
                              </td>
                              <td>
                                {{ item.productKey }}
                              </td>
                              <td>
                                {{ item.productDescription }}
                              </td>
                              <td class="text-center">
                                {{ item.lot }}
                              </td>
                              <td class="text-center">
                                {{ item.expirationDate }}
                              </td>
                              <td style="width:16%" class="text-center">
                                {{ item.quantity }}
                              </td>
                              <td style="width:16%" class="text-center">
                                <span class="italic">
                                  {{ item.quantityReceived }}
                                </span>
                                <br />
                                <small
                                  :class="{
                                    'text-fmedical-yellow':
                                      item.quantityReceived > item.quantity,
                                    'text-fmedical-red':
                                      item.quantityReceived < item.quantity,
                                  }"
                                >
                                  {{ messageQuantity(item) }}
                                </small>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapState, mapMutations } from "vuex";
import { UtilFront, PermissionConstants } from "@/core";
import { ReceptionRequest, ExcelRequest } from "@/core/request";
import ReceptionTableComponent from "@/components/reception/ReceptionTableComponent";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { RECEPTION_HISTORY } from "@/core/DataTableHeadersLocale";
import AlertMessageComponent from "@/common/message/AlertMessageComponent";

export default {
  data() {
    return {
      title: "reception_history.historical",
      remmisionList: [],
      list: [],
      headers: RECEPTION_HISTORY(),
      search: "",
      action: 1,
      sending: false,
      model: {},
      notifier: { notifying: false, type: "", message: "" },
      index: 0,
      loading: null,
      branchOffice: {},
      branchOffices: [],
      leftovers: 0,
      missing: 0,
      permissions: PermissionConstants,
    };
  },
  components: {
    ReceptionTableComponent,
    ValidationProvider,
    ValidationObserver,
    AlertMessageComponent,
  },
  async created() {
    this.branchOffice = this.branchOfficeState;
    this.updateAction(1);
    await this.listAllByBranchOffice();
    this.action = 1;
  },
  computed: {
    // Extración del state de auth para la sucursal global
    ...mapState("auth", { branchOfficeState: "branchOffice" }),
    titleAction() {
      const titles = {
        1: "reception_history.reception_history",
        2: "reception_history.register",
        3: "reception_history.modify",
        4: "reception_history.reception_information",
      };
      return titles[this.action] || "Listado";
    },
    isMoreThanTwoBranchOffices() {
      return this.branchOffices.length > 1;
    },
  },
  methods: {
    withPermissionName(numRol) {
      return UtilFront.withPermissionName(numRol);
    },
    // Funcion para el cambio de sucursal global en el state
    ...mapMutations("auth", {
      updateAction: "UPDATE_ACTION", // Funcion para cambiar el valor del action global
    }),
    showAction(action) {
      return this.action == action;
    },
    changeAction(action) {
      switch (action) {
        case 1:
          this.model = {};
        default:
          this.action = action;
          break;
      }
    },
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    listAllByBranchOffice() {
      this.loading = UtilFront.getLoadding();
      this.list = [];
      ReceptionRequest.listAllByBranchOfficeHistory({
        idBranchOffice: this.branchOfficeState.idBranchoffice,
      })
        .then((res) => {
          let { status, data } = res;
          if (status == 200) {
            this.$refs.tableReceptionHistory.reloadComponent(data);
          } else {
            this.$refs.tableReceptionHistory.reloadComponent([]);
          }
        })
        .catch((error) => {
          this.$toast.error(error);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    getDetail(reception) {
      this.loading = UtilFront.getLoadding();
      const { idReception } = reception;
      ReceptionRequest.getDetailById({ idReception })
        .then((response) => {
          const { status: responseStatus, data: responseBody } = response;
          if (responseStatus == 200) {
            this.model = responseBody;
            this.changeAction(4);
          } else {
            this.alert("error", responseBody);
          }
        })
        .catch((error) => {
          console.error(error);
          this.alert(
            "Se ha producido un error al cargar el detalle de la recepción"
          );
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    messageQuantity(item) {
      let result = item.quantityReceived - item.quantity;
      if (result == 0) {
        return "";
      } else if (result < 0) {
        return this.$t("reception_history.missing_m", { name: result * -1 });
      } else if (item.quantityReceived > item.quantity) {
        return this.$t("reception_history.leftovers_m", { name: result });
      }
    },
    calculateStatus() {
      this.missing = 0;
      this.leftovers = 0;
      this.model.orderStockList.forEach((item) => {
        let result = item.quantityReceived - item.quantity;
        if (result < 0) {
          this.missing = this.missing + result * -1;
        } else if (item.quantityReceived > item.quantity) {
          this.leftovers = this.leftovers + result;
        }
      });
    },
    async excelTable() {
      try {
        this.loading = UtilFront.getLoadding();
        await ExcelRequest.reportReceptionHistory({
          id: this.branchOffice.idBranchoffice,
        });
        this.loading.hide();
        this.$toast.success("Reporte generado (recepcionesHistorico.xlsx)");
      } catch (error) {
        this.loading.hide();
      }
    },
    pdf(item) {
      let send = "idReception=" + item.idReception;
      ExcelRequest.reportReceptionPdf(send);
      this.$toast.success("Generando documento PDF");
    },
  },
  watch: {
    async branchOfficeState() {
      this.branchOffice = this.branchOfficeState;
      await this.listAllByBranchOffice();
    },
    action() {
      this.updateAction(this.action);
    },
    "$i18n.locale": function() {
      this.headers = RECEPTION_HISTORY();
    },
  },
};
</script>

<style>
.br {
  border-radius: 5px;
}
</style>
